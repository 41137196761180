import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { X } from "react-feather";
import { useMutation } from "react-query";
import { EmployerFundTopUpStatus } from "../../models/funding-new/employerFundTopUpStatus";
import {
  EmployerPaymentMethod,
  EmployerPaymentMethodType
} from "../../models/funding-new/employerPaymentMethod";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { PaymentMethodDisplayString } from "./PaymentMethodPreview";

export const AddFundsModal: FC<{
  paymentMethods: EmployerPaymentMethod[];
  open: boolean;
  handleClose: () => void;
}> = ({ paymentMethods, open, handleClose }) => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { employer, employerFund } = useData();

  const [amount, setAmount] = useState<number | undefined>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    EmployerPaymentMethod | undefined
  >();

  const [validationError, setValidationError] = useState<string | undefined>();
  const [apiError, setApiError] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();

  const resetModalState = () => {
    setAmount(undefined);
    setValidationError(undefined);
    setApiError(undefined);
    setSuccessMessage(undefined);
  };

  const calculateAmountWithSurcharge = (baseAmount: number): number => {
    if (!selectedPaymentMethod || !baseAmount) return baseAmount;

    if (
      selectedPaymentMethod.type === EmployerPaymentMethodType.CARD &&
      employer.data?.paymentOptions?.cardPaymentSurchargePercent
    ) {
      const surchargePercent =
        employer.data.paymentOptions.cardPaymentSurchargePercent;
      return baseAmount * (1 + surchargePercent);
    } else if (
      selectedPaymentMethod.type === EmployerPaymentMethodType.DIRECT_DEBIT &&
      employer.data?.paymentOptions?.directDebitPaymentSurchargePercent
    ) {
      const surchargePercent =
        employer.data.paymentOptions.directDebitPaymentSurchargePercent;
      return baseAmount * (1 + surchargePercent);
    }
    return baseAmount;
  };

  const getSurchargeAmount = (baseAmount: number): number => {
    if (!selectedPaymentMethod || !baseAmount) return 0;

    if (
      selectedPaymentMethod.type === EmployerPaymentMethodType.CARD &&
      employer.data?.paymentOptions?.cardPaymentSurchargePercent
    ) {
      const surchargePercent =
        employer.data.paymentOptions.cardPaymentSurchargePercent;
      return baseAmount * surchargePercent;
    } else if (
      selectedPaymentMethod.type === EmployerPaymentMethodType.DIRECT_DEBIT &&
      employer.data?.paymentOptions?.directDebitPaymentSurchargePercent
    ) {
      const surchargePercent =
        employer.data.paymentOptions.directDebitPaymentSurchargePercent;
      return baseAmount * surchargePercent;
    }

    return 0;
  };

  const topUpMutation = useMutation(
    async ({
      amountWithSurcharge,
      employerPaymentMethodId
    }: {
      amountWithSurcharge: number;
      employerPaymentMethodId: string;
    }) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/employer-funds/${employerFund.data?.id}/top-ups`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            amountWithSurcharge,
            employerPaymentMethodId
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem processing your payment");
      }

      return response.json();
    },
    {
      onSuccess: (data: EmployerFundTopUpStatus) => {
        if (
          data === EmployerFundTopUpStatus.APPROVED ||
          data === EmployerFundTopUpStatus.PENDING
        ) {
          handleSuccessMessage(data);
          setApiError(undefined);
          employerFund.refetch();
        } else if (data === EmployerFundTopUpStatus.DECLINED) {
          setApiError(
            "Payment was declined. Please try again or contact support."
          );
        } else if (
          data === EmployerFundTopUpStatus.UNSUPPORTED_PAYMENT_METHOD
        ) {
          setApiError(
            "This payment method is not supported. Please try a different payment method."
          );
        }
      },
      onError: (error: Error) => {
        setApiError(
          "An error occurred while processing your payment. Please try again or contact support."
        );
      }
    }
  );

  const handleSuccessMessage = (status: EmployerFundTopUpStatus) => {
    if (
      status === EmployerFundTopUpStatus.APPROVED &&
      selectedPaymentMethod?.type === EmployerPaymentMethodType.CARD
    ) {
      setSuccessMessage(
        "Your funds have been added successfully, it could take a few minutes for this to reflect in your fund."
      );
      setTimeout(() => {
        resetModalState();
        handleClose();
      }, 3000);
    }

    if (
      status === EmployerFundTopUpStatus.PENDING &&
      selectedPaymentMethod?.type === EmployerPaymentMethodType.DIRECT_DEBIT
    ) {
      setSuccessMessage(
        "Your funds are pending. Direct debit payments can take up to 2-3 business days to process."
      );
      setTimeout(() => {
        resetModalState();
        handleClose();
      }, 3000);
    }
  };

  const validateFields = () => {
    if (!selectedPaymentMethod) {
      setValidationError("Must select a payment method");
      return false;
    }

    if (!amount) {
      setValidationError("Amount is required");
      return false;
    }

    if (amount < 0.5) {
      setValidationError("Amount must be more than $0.50");
      return false;
    }

    setValidationError(undefined);
    return true;
  };

  const handleSubmit = () => {
    if (!validateFields()) return;

    setApiError(undefined);
    const amountWithSurcharge = calculateAmountWithSurcharge(amount ?? 0);
    topUpMutation.mutate({
      amountWithSurcharge: amountWithSurcharge,
      employerPaymentMethodId: selectedPaymentMethod?.id ?? ""
    });
  };

  // Set default payment method to be selected initially
  useEffect(() => {
    const defaultPm = paymentMethods.find((pm) => pm.isDefault);
    setSelectedPaymentMethod(defaultPm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  if (!open) return null;

  const surchargeAmount = getSurchargeAmount(amount ?? 0);
  const amountWithSurcharge = calculateAmountWithSurcharge(amount ?? 0);

  const handleModalClose = () => {
    resetModalState();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>Add Funds</DialogTitle>
        <IconButton onClick={handleModalClose} sx={{ mr: 2 }}>
          <X />
        </IconButton>
      </Box>
      <DialogContent>
        {successMessage ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            <Typography variant="h6">Payment Successful!</Typography>
            <Typography>{successMessage}</Typography>
          </Box>
        ) : (
          <>
            <Box>
              <Typography>Payment Methods</Typography>
              <Select
                value={selectedPaymentMethod?.id || ""}
                onChange={(e) => {
                  const selectedMethod = paymentMethods.find(
                    (method) => method.id === e.target.value
                  );
                  setSelectedPaymentMethod(selectedMethod);
                }}
                size="small"
                fullWidth
              >
                {paymentMethods.map((paymentMethod) => (
                  <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
                    {PaymentMethodDisplayString(paymentMethod)}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box my={2}>
              <Typography>Amount</Typography>
              <TextField
                variant="standard"
                fullWidth
                value={amount}
                onChange={(e) => {
                  const parsedValue = Number(e.currentTarget.value);
                  if (Number.isNaN(parsedValue)) return;
                  setAmount(+e.currentTarget.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
              {amount && amount > 0 ? (
                <Box mt={1}>
                  {selectedPaymentMethod?.type ===
                    EmployerPaymentMethodType.CARD &&
                    employer.data?.paymentOptions
                      ?.cardPaymentSurchargePercent && (
                      <Typography variant="body2" color="text.secondary">
                        Card surcharge (
                        {employer.data.paymentOptions
                          .cardPaymentSurchargePercent * 100}
                        %): ${surchargeAmount.toFixed(2)}
                      </Typography>
                    )}
                  {selectedPaymentMethod?.type ===
                    EmployerPaymentMethodType.DIRECT_DEBIT &&
                    employer.data?.paymentOptions
                      ?.directDebitPaymentSurchargePercent && (
                      <Typography variant="body2" color="text.secondary">
                        Direct Debit surcharge (
                        {employer.data.paymentOptions
                          .directDebitPaymentSurchargePercent * 100}
                        %): ${surchargeAmount.toFixed(2)}
                      </Typography>
                    )}
                  {surchargeAmount > 0 && (
                    <Typography variant="body1" fontWeight="bold">
                      Total amount: ${amountWithSurcharge.toFixed(2)}
                    </Typography>
                  )}
                </Box>
              ) : null}
            </Box>

            {(validationError || apiError) && (
              <Typography color={"error"}>
                {validationError || apiError}
              </Typography>
            )}

            <Button
              variant="contained"
              fullWidth
              disabled={!!validationError || topUpMutation.isLoading}
              onClick={() => handleSubmit()}
            >
              {topUpMutation.isLoading ? (
                <Box display="flex" alignItems="center" gap={1}>
                  <CircularProgress size={20} color="inherit" />
                  Processing...
                </Box>
              ) : (
                "Confirm"
              )}
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
