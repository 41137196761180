import { Box, Button, styled, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AddFundsModal } from "../../components/funding-new/AddFundsModal";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { PageHeader } from "../../components/shared/PageHeader";
import { TabGroup } from "../../components/shared/TabGroup";
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";
import { formatCurrency } from "../../utils/formatters";
import { useData } from "../../utils/useData";
import { Notifications } from "./tabs/Notifications";
import { PaymentMethods } from "./tabs/PaymentMethods";
import { Statements } from "./tabs/Statements";
import { Summary } from "./tabs/Summary";

const PageSubTitle = styled(Typography)({
  fontSize: 28,
  fontWeight: 500,
  marginBottom: 15
});

const FundingNew = () => {
  const { employer, employerFund, paymentMethods } = useData();
  const { setHeaderActions } = useContext(PageHeaderControlContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    employerFund.refetch();
    paymentMethods.refetch();
    setHeaderActions([<></>]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (employerFund.isLoading || employerFund.isIdle || employer.isLoading) {
    return <LoadingSpinner />;
  }

  if (
    employerFund.error ||
    !employerFund.data ||
    employer.error ||
    !employer.data
  ) {
    return <p>Something went wrong, please try again later</p>;
  }

  return (
    <>
      <PageHeader pageTitle="Funding" />
      <AddFundsModal
        paymentMethods={paymentMethods.data?.items || []}
        open={isOpen}
        handleClose={() => setIsOpen(false)}
      />
      <Box display="flex" width="100%" alignItems="center">
        <PageSubTitle>
          {formatCurrency(employerFund.data.availableBalance.amount)}
        </PageSubTitle>
        <Button
          variant="contained"
          sx={{ marginLeft: "auto" }}
          onClick={() => setIsOpen(true)}
        >
          Add Funds
        </Button>
      </Box>
      <TabGroup
        tabLabels={[
          "Summary",
          "Payment Methods",
          "Statements",
          "Notifications"
        ]}
      >
        <Summary />
        <PaymentMethods />
        <Statements />
        <Notifications />
      </TabGroup>
    </>
  );
};

export { FundingNew };
