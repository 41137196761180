import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Navigate, Route, Routes as Router } from "react-router-dom";
import { LoadingSpinner } from "./components/shared/LoadingSpinner";
import { MainLayout } from "./layouts/MainLayout";
import { MembershipType } from "./models";
import { OnboardingProgress } from "./models/onboardingProgress";
import { EmployeeDetails } from "./pages/employees/EmployeeDetails/EmployeeDetails";
import { Employees } from "./pages/employees/Employees";
import { ImportEmployees } from "./pages/employees/ImportEmployees";
import { FundingNew } from "./pages/funding-new/FundingNew";
import { FundingNewCallback } from "./pages/funding-new/FundingNewCallback";
import { Funding } from "./pages/funding/Funding";
import { Home } from "./pages/home/Home";
import { Onboarding } from "./pages/onboarding/Onboarding";
import { PlanDetails } from "./pages/plans/PlanDetails/PlanDetails";
import { Plans } from "./pages/plans/Plans";
import { Reporting } from "./pages/reporting/Reporting";
import { Rewards } from "./pages/rewards/Rewards";
import { Settings } from "./pages/settings/Settings";
import { Transactions } from "./pages/transactions/Transactions";
import { fetchData } from "./utils/fetchData";
import { useConfig } from "./utils/useConfig";
import { useData, useFeatures } from "./utils/useData";
import { AccountsMoved } from "./pages/accountsMoved/AccountsMoved";

export const AuthenticatedRoutes = () => {
  const { config } = useConfig();
  const { employer, membership } = useData();
  const { getToken } = useKindeAuth();
  const { data: features } = useFeatures();
  const [isOnboardingComplete, setIsOnboardingComplete] = useState<
    boolean | null
  >(null);
  const [isSelfService, setIsSelfService] = useState<boolean>(false); // Comes from membership reference type

  const getOnboardingProgress = useQuery<OnboardingProgress>(
    `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employer.data?.id}/onboarding-progress`,
        getToken
      ),
    {
      enabled:
        !!employer.data?.id &&
        !membership.isLoading &&
        membership.data!.type === MembershipType.SELF_SERVICE
    }
  );

  useEffect(() => {
    if (
      !employer.isLoading &&
      isSelfService &&
      !getOnboardingProgress.isLoading &&
      getOnboardingProgress.data
    ) {
      setIsOnboardingComplete(getOnboardingProgress.data.isCompleted);
    }
  }, [
    employer,
    employer.data,
    isSelfService,
    getOnboardingProgress,
    getOnboardingProgress.data,
    getOnboardingProgress.isLoading
  ]);

  useEffect(() => {
    if (!membership.isLoading && membership.data) {
      setIsSelfService(membership.data!.type === MembershipType.SELF_SERVICE);
    }
  }, [membership, membership.isLoading, membership.data]);

  if (
    !isOnboardingComplete &&
    isSelfService &&
    features?.find((f) => f.name === "self-service-onboarding" && f.isEnabled)
  ) {
    // Self-Service Onboarding - Initial walkthrough for first time setup
    return (
      <Router>
        <Route path="/onboarding" Component={Onboarding} />
        <Route path="*" element={<Navigate to="/onboarding" replace />} />
      </Router>
    );
  }

  if (
    employer.isLoading ||
    membership.isLoading ||
    getOnboardingProgress.isLoading
  )
    return <LoadingSpinner />;

  return (
    <MainLayout>
      <Router>
        <Route path="/" Component={Home} />
        <Route path="/users" Component={Employees} />
        <Route path="/users/:employeeId/details" Component={EmployeeDetails} />
        <Route path="/users/import" Component={ImportEmployees} />
        <Route path="/reporting" Component={Reporting} />
        <Route path="/accountsMovedScreen" Component={AccountsMoved} />
        <Route path="/controllable-allowances" Component={Plans} />
        <Route path="/transactions" Component={Transactions} />
        {employer.data?.generalOptions.isRewardsEnabled && (
          <Route path="/rewards" element={<Rewards />} />
        )}
        <Route path="/controllable-allowances/:planId/details" Component={PlanDetails} />
        <Route
          path="/funding"
          Component={
            features?.find((f) => f.name === "employer_fund" && f.isEnabled)
              ? FundingNew
              : Funding
          }
        />
        <Route
          path="/funding/callback"
          Component={
            features?.find((f) => f.name === "employer_fund" && f.isEnabled)
              ? FundingNewCallback
              : Funding
          }
        />
        <Route path="/settings" Component={Settings} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Router>
    </MainLayout>
  );
};
