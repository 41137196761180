import { Box, Button, Grid, Stack, styled, Typography } from "@mui/material";
import Papa from "papaparse";
import { useState } from "react";
import { Download } from "react-feather";
import { InvoicePlatformFees } from "../../../../components/billing/InvoicePlatformFees";
import { InvoiceTransactions } from "../../../../components/billing/InvoiceTransactions";
import { PaymentDialog } from "../../../../components/billing/PaymentDialog";
import { NavBackButton } from "../../../../components/employees/NavBackButton";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner";
import {
  InvoiceStatus,
  InvoiceType,
  LedgerReference
} from "../../../../models";
import { ReportEntity } from "../../../../models/reporting/reportEntity";
import {
  convertObjectKeysToTitleCase,
  downloadCsv
} from "../../../../utils/convertToCSV";
import { formatCurrency, formatDate } from "../../../../utils/formatters";
import { useData } from "../../../../utils/useData";

const InvoiceNumberDisplay = styled(Typography)({
  fontSize: 24,
  fontWeight: 700
});

const InvoiceHeaderLabel = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
  color: "#7B7B7B"
});

const InvoiceHeaderData = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  color: "#454545"
});

const DownloadButton = styled(Button)({
  marginLeft: "auto",
  borderWidth: 2,
  borderColor: "black",
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: 40,
  width: 40,
  minHeight: 40,
  height: 40,
  "&:hover": {
    borderWidth: 2,
    borderColor: "black"
  }
});

const PaidChip = styled(Box)({
  borderRadius: 14,
  backgroundColor: "#D0EAD3",
  color: "#0E8F2B",
  fontSize: 10,
  fontWeight: 600,
  padding: 6,
  paddingLeft: 12,
  paddingRight: 12
});

const DueChip = styled(PaidChip)({
  backgroundColor: "#FAD4D3",
  color: "#BA352B"
});

const GrandTotalDisplay = styled(Typography)({
  fontSize: 16,
  fontWeight: 600
});

const InvoiceHeaderDataDisplay = ({
  label,
  displayData
}: {
  label: string;
  displayData: string;
}) => {
  return (
    <Box>
      <InvoiceHeaderLabel>{label}</InvoiceHeaderLabel>
      <InvoiceHeaderData>{displayData}</InvoiceHeaderData>
    </Box>
  );
};

const handleDownloadCsv = (transactions: LedgerReference[]) => {
  const csvData = transactions?.map((transaction) => {
    const { employeeExternalId, employeeName, sectorName, time, amount } =
      transaction;

    return convertObjectKeysToTitleCase({
      employeeExternalId,
      employeeName,
      sectorName,
      date: formatDate(time),
      amount: formatCurrency(amount)
    });
  });
  downloadCsv(
    Papa.unparse(csvData),
    `${ReportEntity.EMPLOYEES}${new Date().toLocaleString()}`
  );
};

export const InvoiceDetails = ({
  invoiceId,
  setViewingInvoice
}: {
  invoiceId: string;
  setViewingInvoice: Function;
}) => {
  const { invoices, employer } = useData();
  const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);

  if (invoices.isLoading || invoices.isIdle || employer.isLoading) {
    return <LoadingSpinner />;
  }

  if (invoices.error || !invoices.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const invoice = invoices.data.items.find((x) => x.id === invoiceId);

  if (!invoice) {
    return <p>Something went wrong, please try again later</p>;
  }

  const isReadyToPay = invoice.status === InvoiceStatus.READY;

  const isPaymentMade =
    invoice.status === InvoiceStatus.PAID ||
    invoice.status === InvoiceStatus.PAYMENT_PENDING;

  const invoiceTransactions = invoice.includedTransactions ?? [];
  const transactions = invoiceTransactions.sort(
    (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
  );

  const resolveInvoiceUI = () => {
    const invoiceDueDateUTC = new Date(invoice.dueDate)
      .toISOString()
      .split("T")[0];
    const splitDate = "2025-02-01"; // From this date we are now splitting out platform fees and transactions into separate invoices.
    const combineDate = "2025-02-27"; // From this date we are not out platform fees and transactions into separate invoices.

    if (invoiceDueDateUTC < splitDate || invoiceDueDateUTC > combineDate) {
      return (
        <>
          <InvoiceTransactions transactions={transactions} />
          <InvoicePlatformFees invoice={invoice} />
        </>
      );
    } else if (invoice.type === InvoiceType.PLATFORM_FEE) {
      return (
        <>
          <InvoicePlatformFees invoice={invoice} />
        </>
      );
    } else if (invoice.type === InvoiceType.TRANSACTIONS) {
      return <InvoiceTransactions transactions={transactions} />;
    }
  };

  return (
    <>
      <PaymentDialog
        amount={invoice.amount}
        reference={invoice.number}
        title={`Invoice ${invoice.number} for ${invoice.period.title}`}
        open={paymentDialogOpen}
        invoiceId={invoice.id}
        countryId={employer.data?.country.id ?? ""}
        onCancel={() => setPaymentDialogOpen(false)}
        onSuccess={async () => {
          await invoices.refetch();
          setPaymentDialogOpen(false);
        }}
      />

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        marginTop={0}
        rowSpacing={2}
      >
        <NavBackButton onBackPress={() => setViewingInvoice(null)} />

        <Stack direction={"row"} alignItems="center" gap={2} marginTop={2}>
          <InvoiceNumberDisplay>Invoice {invoice.number}</InvoiceNumberDisplay>
          {invoice.status === InvoiceStatus.PAID ? (
            <PaidChip>Paid on {formatDate(invoice.paidOn)}</PaidChip>
          ) : invoice.status === InvoiceStatus.PAYMENT_PENDING ? (
            <PaidChip>Payment Pending</PaidChip>
          ) : (
            <DueChip>DUE</DueChip>
          )}
          <InvoiceHeaderDataDisplay
            label="Period"
            displayData={invoice.period.title}
          />
          <InvoiceHeaderDataDisplay
            label="Due"
            displayData={formatDate(invoice.dueDate)}
          />
          <DownloadButton
            variant="outlined"
            onClick={() => handleDownloadCsv(transactions)}
          >
            <Download />
          </DownloadButton>
          {isReadyToPay && !isPaymentMade && (
            <Button
              variant="contained"
              onClick={() => setPaymentDialogOpen(true)}
              size="medium"
              sx={{ height: "40px" }}
            >
              Pay Now
            </Button>
          )}
        </Stack>

        {resolveInvoiceUI()}

        <Stack
          direction={"row"}
          paddingY={2}
          paddingX={2}
          marginTop={4}
          justifyContent="space-between"
          border={"2px solid black"}
          borderRadius={"10px"}
        >
          <GrandTotalDisplay>Grand Total</GrandTotalDisplay>
          <GrandTotalDisplay>
            {formatCurrency(invoice.amount.amount, 2)}
          </GrandTotalDisplay>
        </Stack>
      </Grid>
    </>
  );
};
