import { LoadingButton } from "@mui/lab";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { LoadingSpinner } from "../shared/LoadingSpinner";

const CollectCardDetails: FC<{ countryId: string; clientSecret: string }> = ({
  countryId,
  clientSecret
}) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const { config, isLoading } = useConfig();
  const { employer } = useData();

  const resolveStripeAccountKey = (countryId: string) => {
    switch (countryId) {
      case "NZ":
        return config?.STRIPE_PUBLIC_KEY_NZ;
      case "AU":
        return config?.STRIPE_PUBLIC_KEY_AU;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const fetchConfig = async (stripeKey: string) => {
      try {
        setStripe(await loadStripe(stripeKey));
      } catch (error) {
        console.error("Error loading Stripe:", error);
        setStripe(null);
      }
    };

    if (config && !isLoading) {
      const key = resolveStripeAccountKey(countryId);
      if (!key) throw new Error("Error resolving stripe key by countryId");
      fetchConfig(key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, isLoading]);

  if (isLoading || employer.isLoading) return <LoadingSpinner />;

  return (
    <Elements stripe={stripe} options={{ clientSecret }}>
      <SetupForm />
    </Elements>
  );
};

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {},
      redirect: "if_required"
    });

    navigate(
      `/funding/callback?result=${error ? "error" : "success"}`
    );
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: 10, marginBottom: 10 }}>
      <PaymentElement />
      <LoadingButton
        variant="contained"
        loading={loading}
        type="submit"
        disabled={loading}
        sx={{ mt: 2 }}
      >
        Submit
      </LoadingButton>
    </form>
  );
};

export default CollectCardDetails;
