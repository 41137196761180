import { Typography } from "@mui/material"
import NavChange from "./navChange.png"
import { PageHeader } from "../../components/shared/PageHeader"
import { useContext, useEffect } from "react";
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";

const AccountsMoved = () => {
    const { setHeaderActions } = useContext(PageHeaderControlContext);

    useEffect(() =>  {
        setHeaderActions([]);
    },
    
    [])
    return <>
        <PageHeader pageTitle="Accounts" />
        <Typography sx={{ paddingBottom: 2}}>
            Accounts are now named Controllable Allowances and can be accessed on the left navigation.
        </Typography>
        <img src={NavChange} alt="NavChange" width={200} />
    </>
}

export { AccountsMoved }