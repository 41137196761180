import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { TabGroup } from "../../../components/shared/TabGroup";
import { EmployerFundLedgerTable } from "../../../components/table/EmployerFundLedger/EmployerFundLedgerTable";
import { EmployerFundTopUpsTable } from "../../../components/table/EmployerFundTopUp/EmployerFundTopUpsTable";
import { PaginatedDateRangeFilter } from "../../../components/table/PaginatedDateRangeFilter";
import { Pagination } from "../../../models";
import { EmployerFundLedger } from "../../../models/funding-new/employerFundLedger";
import { EmployerFundLedgerType } from "../../../models/funding-new/employerFundLedgerType";
import { EmployerFundTopUp } from "../../../models/funding-new/employerFundTopUp";
import { useCustomQuery, useData } from "../../../utils/useData";

// Helper function to build query strings
const buildQueryString = (params: Record<string, any>): string => {
  return Object.entries(params)
    .flatMap(([key, value]) => {
      if (value === undefined || value === null || value === "") {
        return [];
      }
      // If the value is an array, map each value to the same key
      if (Array.isArray(value)) {
        return value.map(
          (v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
        );
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
};

export const Statements = () => {
  const { employerFund } = useData();
  const { getClaim } = useKindeAuth();
  const employerId = getClaim("external_org_id")?.value;

  const [topUpsPage, setTopUpsPage] = useState(0);
  const [topUpsPageSize, setTopUpsPageSize] = useState(100);
  const [platformFeesPage, setPlatformFeesPage] = useState(0);
  const [platformFeesPageSize, setPlatformFeesPageSize] = useState(100);
  const [transactionsPage, setTransactionsPage] = useState(0);
  const [transactionsPageSize, setTransactionsPageSize] = useState(10);

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const topUpsQueryString = buildQueryString({
    fromDate: startDate ? startDate.toISOString() : undefined,
    toDate: endDate ? endDate.toISOString() : undefined
  });

  const platformFeesQueryString = buildQueryString({
    type: [
      EmployerFundLedgerType.ISSUE_CARD_FEE,
      EmployerFundLedgerType.RE_ISSUE_CARD_FEE,
      EmployerFundLedgerType.MONTHLY_USER_FEE,
      EmployerFundLedgerType.LOAD_FEE
    ],
    fromDate: startDate ? startDate.toISOString() : undefined,
    toDate: endDate ? endDate.toISOString() : undefined
  });

  const transactionsQueryString = buildQueryString({
    type: EmployerFundLedgerType.TRANSACTION,
    fromDate: startDate ? startDate.toISOString() : undefined,
    toDate: endDate ? endDate.toISOString() : undefined
  });

  const topUps = useCustomQuery<Pagination<EmployerFundTopUp>>({
    endpoint: `/employers/${employerId}/employer-funds/${employerFund.data?.id}/top-ups`,
    queryKey: `top-ups-${topUpsPage}-${topUpsPageSize}`,
    queryString: topUpsQueryString,
    pagination: {
      page: topUpsPage,
      pageSize: topUpsPageSize
    },
    options: {
      enabled: !!employerId && !!employerFund.data?.id
    }
  });

  const platformFees = useCustomQuery<Pagination<EmployerFundLedger>>({
    endpoint: `/employers/${employerId}/employer-funds/${employerFund.data?.id}/ledgers`,
    queryKey: `platform-fees-${platformFeesPage}-${platformFeesPageSize}`,
    queryString: platformFeesQueryString,
    pagination: {
      page: platformFeesPage,
      pageSize: platformFeesPageSize
    },
    options: {
      enabled: !!employerId && !!employerFund.data?.id
    }
  });

  const transactions = useCustomQuery<Pagination<EmployerFundLedger>>({
    endpoint: `/employers/${employerId}/employer-funds/${employerFund.data?.id}/ledgers`,
    queryKey: `transactions-${transactionsPage}-${transactionsPageSize}`,
    queryString: transactionsQueryString,
    pagination: {
      page: transactionsPage,
      pageSize: transactionsPageSize
    },
    options: {
      enabled: !!employerId && !!employerFund.data?.id
    }
  });

  useEffect(() => {
    topUps.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topUpsPage, topUpsPageSize, startDate, endDate]);

  useEffect(() => {
    platformFees.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformFeesPage, platformFeesPageSize, startDate, endDate]);

  useEffect(() => {
    transactions.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsPage, transactionsPageSize, startDate, endDate]);

  return (
    <>
      <Box display="flex" width="100%" justifyContent="flex-end" mt={4}>
        <PaginatedDateRangeFilter
          startDate={startDate}
          endDate={endDate}
          updateStartDate={setStartDate}
          updateEndDate={setEndDate}
        />
      </Box>
      <Stack spacing={3}>
        <TabGroup tabLabels={["Top Ups", "Platform Fees", "Transactions"]}>
          <EmployerFundTopUpsTable
            topUps={topUps.data?.items ?? []}
            totalItems={topUps.data?.totalItems ?? 0}
            page={topUpsPage}
            pageSize={topUpsPageSize}
            updatePage={setTopUpsPage}
            updatePageSize={setTopUpsPageSize}
            isLoading={topUps.isLoading}
          />

          <EmployerFundLedgerTable
            ledgers={platformFees.data?.items ?? []}
            totalItems={platformFees.data?.totalItems ?? 0}
            page={platformFeesPage}
            pageSize={platformFeesPageSize}
            updatePage={setPlatformFeesPage}
            updatePageSize={setPlatformFeesPageSize}
            isLoading={platformFees.isLoading}
          />

          <EmployerFundLedgerTable
            ledgers={transactions.data?.items ?? []}
            totalItems={transactions.data?.totalItems ?? 0}
            page={transactionsPage}
            pageSize={transactionsPageSize}
            updatePage={setTransactionsPage}
            updatePageSize={setTransactionsPageSize}
            isLoading={transactions.isLoading}
          />
        </TabGroup>
      </Stack>
    </>
  );
};
