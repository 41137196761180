import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useConfig } from "../../../utils/useConfig";
import { useData } from "../../../utils/useData";

export const Notifications = () => {
  const { employer, employerFund } = useData();
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const [thresholdAmount, setThresholdAmount] = useState<number>(0);
  const [emailEnabled, setEmailEnabled] = useState<boolean>(false);
  const [smsEnabled, setSmsEnabled] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const updateNotificationSettingsMutation = useMutation(
    async () => {
      await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/employer-funds/${employerFund.data?.id}/notification-settings`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            thresholdAmount,
            emailEnabled,
            smsEnabled
          })
        }
      );
    },
    {
      onSuccess: () => {
        employerFund.refetch();
        toast.success("Notification settings updated successfully");
        setError("");
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const handleSubmit = async () => {
    if (thresholdAmount <= 0) {
      setError("Threshold amount must be greater than 0");
      return;
    }

    updateNotificationSettingsMutation.mutate();
  };

  useEffect(() => {
    if (employerFund.data?.notificationSettings) {
      setThresholdAmount(
        employerFund.data?.notificationSettings.thresholdAmount ?? 0
      );
      setEmailEnabled(
        employerFund.data?.notificationSettings.emailEnabled ?? false
      );
      setSmsEnabled(
        employerFund.data?.notificationSettings.smsEnabled ?? false
      );
    }
  }, [employerFund.data]);

  return (
    <Box sx={{ p: 3 }}>
      <Typography sx={{ mb: 3 }}>
        When funding is required, notifications will be sent via the following
        methods
      </Typography>

      <Grid container item xs={6} spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Balance trigger for notifications"
            value={thresholdAmount}
            onChange={(e) => {
              const parsedValue = Number(e.currentTarget.value);
              if (Number.isNaN(parsedValue)) return;
              setThresholdAmount(+e.currentTarget.value);
            }}
            fullWidth
            size="small"
            InputProps={{
              startAdornment: "$"
            }}
            error={!!error}
            helperText={error}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={emailEnabled}
                onChange={(e) => setEmailEnabled(e.target.checked)}
              />
            }
            label="Email Notifications"
          />
          <TextField
            value={employer.data?.contact.email || ""}
            disabled
            fullWidth
            size="small"
            sx={{ mt: 1 }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={smsEnabled}
                onChange={(e) => setSmsEnabled(e.target.checked)}
              />
            }
            label="SMS Notifications"
          />
          <TextField
            value={employer.data?.contact.phone || ""}
            disabled
            fullWidth
            size="small"
            sx={{ mt: 1 }}
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            sx={{ mt: 2 }}
            loading={updateNotificationSettingsMutation.isLoading}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
