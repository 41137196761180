import { Autorenew, HistoryOutlined } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { ReactComponent as AllCategories } from "../../../assets/all-categories.svg";
import { ReactComponent as Calendar } from "../../../assets/calendar.svg";
import { ReactComponent as RestrictedCategories } from "../../../assets/restricted-categories.svg";
import { PlanType, RenewalOptions, RenewalStrategy, ServiceSector, Tier } from "../../../models";
import { formatCurrency } from "../../../utils/formatters";
import { getOpenServiceSector } from "../../../utils/serviceSectors/getOpenServiceSector";
import { extraordinaryColors } from "../../../utils/theme";
import { SectorDisplay } from "./SectorDisplay";
import { TierDisplay } from "./TierDisplay";
import { VariantStyleDictionary } from "./VariantStyleDictionary";

const renewalTextDictionary: Record<number, [string, string]> = {
  1: ['Renews Monthly', 'Expires in 1 month'],
  2: ['Renews Every 2 Months', 'Expires in 2 months'],
  3: ['Renews Every 3 Months', 'Expires in 3 months.'],
  6: ['Renews Bi-Annually', 'Expires in 6 months'],
  12: ['Renews Yearly', 'Expires in 1 year'],
  24: ['Renews Every 2 Years', 'Expires in 2 years']
}

export type AccountSummaryData = {
  name: string,
  tiers: Tier[],
  serviceSectorIds: string[],
  planType: PlanType,
  renewalOptions: RenewalOptions
}

export const AccountSummary: FC<{
  variant: "full" | "preview" | "fullColor";
  planInfo: AccountSummaryData;
  serviceSectors: ServiceSector[] | undefined;
  displayActivationDate?: boolean;
  displayRenewalStrategy?: boolean;
  expiryOrRenewalDateToDisplay?: Date;
  remainingBalanceToDisplay?: number;
}> = ({ variant, planInfo, serviceSectors, displayActivationDate, displayRenewalStrategy, expiryOrRenewalDateToDisplay, remainingBalanceToDisplay }) => {
  const theme = useTheme();
  const openServiceSector = getOpenServiceSector(serviceSectors ?? []);

  const { sectionHeaderStyle, iconStyle } = VariantStyleDictionary[variant]

  const isOpenAccount = planInfo.serviceSectorIds.find(
    (ssid) => ssid === openServiceSector?.id
  )
  
  return (
    <Box>
      <Box display="flex" gap={2}>
        {/* RENEWAL */}
        {planInfo.planType &&
          planInfo.renewalOptions.periodInMonths && (
            <Box display="flex" gap={0.5} alignItems="center" mt={1}>
              {planInfo.planType === PlanType.ONGOING ? (
                <>
                  <Autorenew sx={iconStyle} />
                  <Typography fontSize={14}>
                    {renewalTextDictionary[planInfo.renewalOptions.periodInMonths]?.[0] ?? `${planInfo.renewalOptions.periodInMonths} months`}
                  </Typography> 
                </>
              ) : (
                <>

                  <Calendar style={iconStyle} />
                  <Typography fontSize={14} color={theme.palette.grey[600]}>
                    One-Off
                  </Typography>
                  <HistoryOutlined
                    sx={{ ml: 2, ...iconStyle }}
                  />
                  <Typography fontSize={14} color={theme.palette.grey[600]}>
                    {renewalTextDictionary[planInfo.renewalOptions.periodInMonths]?.[1] ?? `${planInfo.renewalOptions.periodInMonths} months`}
                  </Typography>
                </>
              )}
            </Box>
          )}

        {planInfo.serviceSectorIds.length > 0 && (
          <Box display="flex" gap={0.5} alignItems="center" mt={1}>
            {isOpenAccount ? (
              <AllCategories style={iconStyle} />
            ) : (
              <RestrictedCategories style={iconStyle} />
            )}

            <Typography fontSize={14}>
              {isOpenAccount
                ? "All Categories"
                : "Restricted"}
            </Typography>
          </Box>
        )}
      </Box>

      {expiryOrRenewalDateToDisplay && (
        <Box display="flex" gap={0.5} alignItems="center" mt={1}>
          <Typography fontSize={14} color="grey">
            {planInfo.planType === PlanType.ONGOING
              ? "Renewal Date: "
              : "Expiry Date: "}
             <span style={{ color: extraordinaryColors.green.main }}>{new Date(expiryOrRenewalDateToDisplay).toLocaleDateString('en-NZ')}</span>
          </Typography>
        </Box>
      )}

      {remainingBalanceToDisplay !== null && remainingBalanceToDisplay !== undefined && (
        <Box display="flex" gap={0.5} alignItems="center">
          <Typography fontSize={14} color="grey">
            Remaining Balance: <span style={{ color: extraordinaryColors.green.main }}>{formatCurrency(remainingBalanceToDisplay)}</span>
          </Typography>
        </Box>
      )}

      {/* TIERS */}
      {planInfo.tiers.length > 0 && (
        <TierDisplay 
          variant={variant}
          tierInfo={planInfo} />
      )}

      {/* HEALTHCARE BENEFITS / SERVICESECTORS */}
      {
        variant !== "preview" && planInfo.serviceSectorIds.length > 0 && (
          <SectorDisplay 
            variant={variant}
            serviceSectorIds={planInfo.serviceSectorIds}
            isOpenAccount={isOpenAccount} />
        )
      }

      { (displayActivationDate || displayRenewalStrategy) && 
      <Box
        display="flex"
        justifyContent={"space-between"}
        gap={0}
        mt={1}
      >
        { displayActivationDate && <Box>
          <Typography sx={sectionHeaderStyle}>
            ACCOUNT ACTIVATES
          </Typography>
          <Typography fontSize={14}>
            {planInfo.renewalOptions.startDate
              ? new Date(
                planInfo.renewalOptions.startDate
                ).toLocaleDateString("en-NZ")
              : "Date Employee Added"}
          </Typography>
        </Box>}
        {(displayRenewalStrategy && planInfo.planType === PlanType.ONGOING) && (
          <Box>
            <Typography sx={sectionHeaderStyle}>
              RESIDUAL BALANCE
            </Typography>
            <Typography fontSize={14}>
              {planInfo.renewalOptions.strategy === RenewalStrategy.RESET
                ? "Resets on Renewal"
                : "Rolls over"}
            </Typography>
          </Box>
        )}
      </Box> }
    </Box>
  );
};
