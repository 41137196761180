import { Box } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { FC } from "react";
import { EmployerFundTopUp } from "../../../models/funding-new/employerFundTopUp";
import {
  formatCurrency,
  formatDate,
  formatPercentage
} from "../../../utils/formatters";
import { LoadingSpinner } from "../../shared/LoadingSpinner";
import { Table } from "../../shared/Table";

const EmployerFundTopUpsTable: FC<{
  topUps: EmployerFundTopUp[];
  totalItems: number;
  page: number;
  pageSize: number;
  updatePage: (value: number) => void;
  updatePageSize: (value: number) => void;
  isLoading: boolean;
}> = ({
  topUps,
  totalItems,
  page,
  pageSize,
  updatePage,
  updatePageSize,
  isLoading
}) => {
  const toTitleCase = (str: string): string =>
    str
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase());

  const columns: ColumnDef<EmployerFundTopUp>[] = [
    {
      header: "Status",
      cell: ({ row }) => {
        const titleCase = toTitleCase(row.original.status);
        return <Box>{titleCase}</Box>;
      },
      enableColumnFilter: false
    },
    {
      cell: ({ row }) => (
        <Box>{formatCurrency(row.original.amount.amount)}</Box>
      ),
      header: "Amount",
      enableColumnFilter: false
    },
    {
      cell: ({ row }) => (
        <Box>{formatPercentage(row.original.surcharge * 100)}</Box>
      ),
      header: "Surcharge",
      enableColumnFilter: false
    },
    {
      cell: ({ row }) => (
        <Box>{formatCurrency(row.original.amountWithSurcharge.amount)}</Box>
      ),
      header: "Total",
      enableColumnFilter: false
    },
    {
      header: "Date",
      meta: {
        filterType: "dateRange"
      },
      enableColumnFilter: false,
      cell: ({ row }) => {
        const formattedDate = formatDate(row.original.createdAt);
        return <Box>{formattedDate}</Box>;
      }
    }
  ];

  if (isLoading) return <LoadingSpinner />;

  return (
    <Table<EmployerFundTopUp>
      data={topUps}
      totalItems={totalItems}
      columns={columns}
      isServerSidePagination={true}
      paginationPage={page}
      paginationPageSize={pageSize}
      onPageChange={updatePage}
      onPageSizeChange={updatePageSize}
    />
  );
};

export { EmployerFundTopUpsTable };
