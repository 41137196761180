import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import CollectCardDetails from "../../../components/funding-new/CollectCardDetails";
import { PaymentMethodPreview } from "../../../components/funding-new/PaymentMethodPreview";
import { LoadingSpinner } from "../../../components/shared/LoadingSpinner";
import { useConfig } from "../../../utils/useConfig";
import { useData } from "../../../utils/useData";

export const PaymentMethods = () => {
  const { getToken } = useKindeAuth();
  const { employer, paymentMethods } = useData();
  const { config } = useConfig();

  const [error, setError] = useState("");
  const [secret, setSecret] = useState("");

  const initGoCardless = useMutation(
    async () => {
      const token = await getToken();
      const response = await fetch(
        `${config?.API_URL}/go-cardless/direct-debit-mandate-flow`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            employerId: employer.data?.id,
            portalUrl: window.location.href
          })
        }
      );

      return await response.json();
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const initStripe = useMutation(
    async () => {
      const token = await getToken();
      const response = await fetch(
        `${config?.API_URL}/stripe-payments/setup-intent`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            employerId: employer.data?.id
          })
        }
      );

      return await response.json();
    },
    {
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const updateDefault = useMutation(
    async (id: string) => {
      const token = await getToken();
      await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/payment-methods/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );
    },
    {
      onSuccess: () => {
        paymentMethods.refetch();
        toast.success("Default payment method updated");
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const deletePaymentMethod = useMutation(
    async (id: string) => {
      const token = await getToken();
      await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/payment-methods/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );
    },
    {
      onSuccess: () => {
        paymentMethods.refetch();
        toast.success("Payment method deleted");
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  if (paymentMethods.isLoading || paymentMethods.isIdle) {
    return <LoadingSpinner />;
  }

  if (paymentMethods.error || !paymentMethods.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const initCard = async () => {
    let response = await initStripe.mutateAsync();
    setSecret(response.secret);
  };

  const initDirectDebit = async () => {
    let response = await initGoCardless.mutateAsync();
    window.location.href = response.url;
  };

  return (
    <Grid container spacing={5} flexDirection="column">
      <Grid item>
        {initStripe.isLoading || initGoCardless.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Grid item display="flex" justifyContent="flex-end" mt={1}>
            <Button color="primary" variant="contained" onClick={initCard}>
              Add Card
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={initDirectDebit}
              sx={{ ml: 2 }}
            >
              Add Direct Debit
            </Button>
          </Grid>
        )}

        {secret && employer.data?.country.id && (
          <CollectCardDetails
            countryId={employer.data?.country.id}
            clientSecret={secret}
          />
        )}

        <Box minWidth="fit-content">
          {paymentMethods.data.items.length > 0 &&
            paymentMethods.data.items
              .sort((a, b) => (a.isDefault ? -1 : 1))
              .map((pm) => {
                return (
                  <PaymentMethodPreview
                    paymentMethod={pm}
                    updateDefault={updateDefault.mutate}
                    deletePaymentMethod={deletePaymentMethod.mutate}
                  />
                );
              })}

          {error && <Typography>{error}</Typography>}
        </Box>
      </Grid>
    </Grid>
  );
};
