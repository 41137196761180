import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ErrorOutline } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";
import { ColumnDef, FilterFn, Row } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { Download, FileText } from "react-feather";
import { Transaction, TransactionType } from "../../../models";
import { TransactionVerificationInfoFileResponse } from "../../../models/transactions/transactionVerificationInfoFileResponse";
import {
  convertTransactionsToCsv,
  downloadCsv
} from "../../../utils/convertToCSV";
import { fetchData } from "../../../utils/fetchData";
import { formatCurrency, formatDate } from "../../../utils/formatters";
import { useConfig } from "../../../utils/useConfig";
import { useData } from "../../../utils/useData";
import { LoadingSpinner } from "../../shared/LoadingSpinner";
import { Table } from "../../shared/Table";

const EMPLOYEE_HEADER = "User";
const ACCOUNT_HEADER = "Account";
const filterUserColumn = (col: ColumnDef<Transaction>) =>
  col.header !== EMPLOYEE_HEADER;
const removeUserColumn = (columns: ColumnDef<Transaction>[]) =>
  columns.filter(filterUserColumn);

const filterAccountColumn = (col: ColumnDef<Transaction>) =>
  col.header !== ACCOUNT_HEADER;
const removeAccountColumn = (columns: ColumnDef<Transaction>[]) =>
  columns.filter(filterAccountColumn);

const TransactionTable = ({
  employeeId,
  planId,
  enableSearch,
  enableFilters,
  enableExport
}: {
  employeeId?: string;
  planId?: string;
  enableSearch?: boolean;
  enableFilters?: boolean;
  enableExport?: boolean;
}) => {
  const {
    employer,
    transactions,
    employees,
    accounts,
    serviceSectors,
    plans,
    tagCategories
  } = useData();

  const { config } = useConfig();
  const { getToken } = useKindeAuth();

  const [accountIdToName, setAccountIdToName] = useState<Record<string, string>>({});

  useEffect(() => {
    if (accounts.data?.items) {
      const accountMap: Record<string, string> = {};
      accounts.data.items.forEach(account => {
        if (account.id && account.name) {
          accountMap[account.id] = account.name;
        }
      });
      setAccountIdToName(accountMap);
    }
  }, []);

  const filterData = useMemo(() => {
    if (employeeId !== undefined) {
      return transactions.data?.items.filter(
        (transaction) => transaction.employeeId === employeeId
      );
    } else if (planId !== undefined) {
      return transactions.data?.items.filter(
        (transaction) => transaction.charges?.some(charge => charge.planId === planId)
      );
    }
    return transactions.data?.items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions.data?.items]);

  const [filteredTableRowData, setFilteredTableRowData] =
    useState<Transaction[]>();
  const [filteredTableRowDataCsv, setFilteredTableRowDataCsv] =
    useState<string>();

  const handleDownload = () => {
    downloadCsv(
      filteredTableRowDataCsv ?? "",
      `Transactions-${new Date().toLocaleString()}`
    );
  };

  useEffect(() => {
    const csv = convertTransactionsToCsv(
      filteredTableRowData ?? [],
      false,
      serviceSectors.data?.items,
      employees.data?.items,
      tagCategories.data?.items,
      plans.data?.items
    );
    setFilteredTableRowDataCsv(csv);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTableRowData]);

  const dateRangeFilterFn: FilterFn<any> = (row, columnId, filterValues) => {
    const cellValue = new Date(row.getValue(columnId));
    const { start, end } = filterValues;

    // If no filter values, return true (no filter applied)
    if (!start && !end) return true;

    // Check if the date is within the range
    const isAfterStart = start ? cellValue >= new Date(start) : true;
    const isBeforeEnd = end ? cellValue <= new Date(end) : true;

    return isAfterStart && isBeforeEnd;
  };

  const getVerificationFile = async (transactionId: string) => {
    const url = `${config?.API_URL}/transactions/${transactionId}/verification-info/file`;
    const data: TransactionVerificationInfoFileResponse = await fetchData(
      url,
      getToken
    );
    return data;
  };

  const verificationColumns = [
    {
      accessorFn: (row: Transaction) =>
        row.requireVerification ? "Yes" : "No",
      cell: ({ row }: { row: Row<Transaction> }) => (
        <Box>{row.original?.requireVerification ? "Yes" : "No"}</Box>
      ),
      header: "Receipt Required",
      meta: {
        filterLabel: "Receipt Required",
        filterSelectOptions: [
          { option: "Yes", value: "Yes" },
          { option: "No", value: "No" }
        ]
      },
      filterFn: (row: any, columnId: string, filterValues: string[]) => {
        const value = row.getValue(columnId);
        return filterValues.includes(value);
      }
    },
    {
      accessorFn: (row: Transaction) =>
        row.verificationInfo?.isComplete ? "Yes" : "No",
      cell: ({ row }: { row: Row<Transaction> }) => (
        <Box>
          {!row.original.requireVerification ? (
            "N/A"
          ) : row.original.verificationInfo?.isComplete ? (
            <FileText
              onClick={async () => {
                const data = await getVerificationFile(row.original.id);
                window.open(data.url, "_blank");
              }}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Tooltip title="This transaction receipt has yet to be uploaded">
              <ErrorOutline />
            </Tooltip>
          )}
        </Box>
      ),
      header: "Receipt",
      meta: {
        filterLabel: "Receipt",
        filterSelectOptions: [
          { option: "Uploaded", value: "Yes" },
          { option: "Not Uploaded Yet", value: "No" }
        ]
      },
      filterFn: (row: any, columnId: string, filterValues: string[]) => {
        if (!row.original.requireVerification) return false;
        const value = row.getValue(columnId);
        return filterValues.includes(value);
      }
    }
  ];

  const columns = useMemo<ColumnDef<Transaction>[]>(() => {
    const baseColumns: ColumnDef<Transaction>[] = [
      {
        accessorFn: (row) => 
          row.type === TransactionType.ADJUSTMENT || 
          row.type === TransactionType.REIMBURSEMENT ? 
            row.type : `${row.cardAcceptor}`,
        header: "Merchant",
        sortingFn: (rowA, rowB) =>
          rowA.original.cardAcceptor < rowB.original.cardAcceptor
            ? -1
            : rowA.original.cardAcceptor === rowB.original.cardAcceptor
            ? 0
            : 1,
        enableColumnFilter: false
      },
      {
        accessorFn: (row) => new Date(row.timestamp),
        header: "Date",
        filterFn: dateRangeFilterFn,
        meta: { filterType: "dateRange" },
        enableColumnFilter: true,
        cell: ({ row }) => {
          const formattedDate = formatDate(row.original.timestamp);
          return <Box>{formattedDate}</Box>;
        }
      },
      {
        accessorFn: (row) => row.charges?.reduce<string | undefined>((charges, currentCharge) => {
          if(planId && currentCharge.planId !== planId) return charges;
          if(currentCharge.amount.amount !== undefined) {
            if(!charges) {
              return formatCurrency(currentCharge.amount.amount);
            }
            return charges + ", " + formatCurrency(currentCharge.amount.amount);
          }
          return charges;
        }, undefined) ?? "",
        header: "Amount",
        enableColumnFilter: false
      },
      {
        accessorFn: (row) => row.status,
        header: "Status",
        enableColumnFilter: false
      }
    ];

    // If verification is required, insert the verification columns
    if (employer.data?.generalOptions.requirePostTransactionVerification) {
      baseColumns.push(verificationColumns[0]);
      baseColumns.push(verificationColumns[1]);
    }

    baseColumns.push(
      {
        accessorFn: (row) => {
          const employee = employees.data?.items.find(
            (value) => value.id === row.employeeId
          );
          return `${employee?.firstName} ${employee?.lastName}`;
        },
        header: EMPLOYEE_HEADER,
        enableColumnFilter: false
      },
      {
        accessorFn: (row) =>
          serviceSectors.data?.items.find(
            (sector) => sector.id === row.serviceSectorId
          )?.name ?? "N/A",
        header: "Category",
        enableColumnFilter: false
      },
      {
        accessorFn: (row) => {
          const accountNames = row.charges?.reduce<string | undefined>((names, charge) => {
            if(planId && planId !== charge.planId) return names;
            const accountName = charge.accountId ? accountIdToName[charge.accountId] : undefined;
            if (accountName) {
              if (!names) {
                return accountName;
              }
              return names + ", " + accountName;
            }
            return names;
          }, undefined) || "N/A";
          
          return accountNames;
        },
        header: "Account",
        meta: {
          filterLabel: "Account",
          filterSelectOptions: [
            ...(plans.data?.items?.map((plan) => ({
              option: plan.name,
              value: plan.name
            })) || [])
          ]
        },
        filterFn: (row: any, columnId, filterValues: string) => {
          const planName = row.getValue(columnId);
          return filterValues.includes(planName);
        }
      }
    );

    return baseColumns;
  }, [employer.data, accounts.data]);

  if (
    transactions.isLoading ||
    transactions.isIdle ||
    employees.isLoading ||
    employees.isIdle ||
    accounts.isLoading ||
    accounts.isIdle ||
    serviceSectors.isLoading ||
    serviceSectors.isIdle ||
    employer.isLoading ||
    employer.isIdle
  )
    return <LoadingSpinner />;

  if (transactions.error || !transactions.data)
    return <p>Something went wrong, please try again later</p>;

  var filteredColumns;

  if (employeeId) {
    filteredColumns = removeUserColumn(columns);
  }

  if (planId) {
    filteredColumns = removeAccountColumn(columns);
  }

  return (
    <>
      {enableExport && (
        <Button
          variant="outlined"
          onClick={() => handleDownload()}
          startIcon={<Download />}
          size="medium"
          sx={{ maxWidth: 100, ml: "auto", mr: 2, mt: 1 }}
        >
          Export
        </Button>
      )}
      <Table<Transaction>
        data={filterData ?? []}
        totalItems={transactions.data.totalItems}
        columns={filteredColumns ?? columns}
        defaultSorting={[{ id: "Date", desc: true }]}
        displayFilters={enableFilters}
        displaySearch={enableSearch}
        onFilteredDataChange={(data) => setFilteredTableRowData(data)}
      />
    </>
  );
};

export { TransactionTable };
