import { Box, Typography } from "@mui/material";
import { FC } from "react";
import {
  EmployerPaymentMethod,
  EmployerPaymentMethodType
} from "../../models/funding-new/employerPaymentMethod";
import { extraordinaryColors } from "../../utils/theme";
import { PaymentMethodMenu } from "./PaymentMethodMenu";

export const PaymentMethodDisplayString = (
  paymentMethod: EmployerPaymentMethod
) => {
  const cardDetails = paymentMethod.cardDetails;
  const directDebitDetails = paymentMethod.directDebitDetails;

  if (paymentMethod.type === EmployerPaymentMethodType.CARD) {
    return `${cardDetails?.brand} **** ${cardDetails?.last4}`;
  }

  if (paymentMethod.type === EmployerPaymentMethodType.DIRECT_DEBIT) {
    return `
      ${directDebitDetails?.bankName} (${directDebitDetails?.countryCode}) ******
      ${directDebitDetails?.accountNumberEnding}`;
  }

  return null;
};

export const PaymentMethodPreview: FC<{
  paymentMethod: EmployerPaymentMethod;
  updateDefault: (id: string) => void;
  deletePaymentMethod: (id: string) => void;
}> = ({ paymentMethod, updateDefault, deletePaymentMethod }) => {
  const cardDetails = paymentMethod.cardDetails;

  return (
    <Box my={2} border="1px solid lightgrey" borderRadius={2} padding={1.5}>
      <Box display="flex">
        <Typography>{PaymentMethodDisplayString(paymentMethod)}</Typography>

        {paymentMethod.isDefault && (
          <Box
            sx={{
              fontSize: 10,
              padding: 0.5,
              borderRadius: 1,
              fontWeight: 600,
              bgcolor: extraordinaryColors.green.main,
              color: "white",
              maxWidth: "fit-content",
              marginLeft: 1
            }}
          >
            Default
          </Box>
        )}

        <Box display="flex" marginLeft="auto">
          {paymentMethod.type === EmployerPaymentMethodType.CARD && (
            <Typography marginRight={4} color="grey" fontSize={14} my="auto">
              Expires {cardDetails?.expMonth}/{cardDetails?.expYear}
            </Typography>
          )}

          <PaymentMethodMenu
            isDefault={paymentMethod.isDefault}
            updateDefault={() => updateDefault(paymentMethod.id)}
            deletePaymentMethod={() => deletePaymentMethod(paymentMethod.id)}
          />
        </Box>
      </Box>

      <Typography fontSize={12} color="grey">
        {paymentMethod.type === EmployerPaymentMethodType.CARD
          ? "Card"
          : "Direct Debit"}
      </Typography>
    </Box>
  );
};
