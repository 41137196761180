import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/shared/PageHeader";
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";
import { extraordinaryColors } from "../../utils/theme";

const FundingNewCallback = () => {
  const { setHeaderActions } = useContext(PageHeaderControlContext);
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    setHeaderActions([<></>]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const resultParam = queryParams.get("result");
    setIsSuccess(resultParam === "success");
  }, []);

  useEffect(() => {
    if (isSuccess === undefined) return;

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate("/funding");
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isSuccess, navigate]);

  return (
    <>
      <PageHeader pageTitle="Funding" />
      <Box display="flex" flexDirection="column" width="100%" gap={2} mt={20}>
        {isSuccess ? (
          <CheckCircleOutline
            sx={{
              fontSize: 60,
              mx: "auto",
              color: extraordinaryColors.green.light
            }}
          />
        ) : (
          <ErrorOutline
            sx={{
              fontSize: 60,
              mx: "auto",
              color: extraordinaryColors.red.main
            }}
          />
        )}

        {isSuccess ? (
          <Typography variant="h5" textAlign="center">
            Successfully added Card payment method
          </Typography>
        ) : (
          <>
            <Typography variant="h5" textAlign="center">
              An error occurred adding Card payment method
            </Typography>
            <Typography textAlign="center">
              Please reach out to support.
            </Typography>
          </>
        )}

        {isSuccess && (
          <>
            <Typography
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center"
              }}
            >
              You will be redirected in {countdown} second
              {countdown !== 1 ? "s" : ""}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export { FundingNewCallback };
