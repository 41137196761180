import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";

export const PaymentMethodMenu: FC<{
  isDefault: boolean;
  updateDefault: () => void;
  deletePaymentMethod: () => void;
}> = ({ isDefault, updateDefault, deletePaymentMethod }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateDefault = () => {
    updateDefault();
    handleClose();
  };

  const handleDeletePaymentMethod = () => {
    deletePaymentMethod();
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} sx={{ padding: 0 }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem disabled={isDefault} onClick={handleUpdateDefault}>
          Set as Default
        </MenuItem>
        <MenuItem disabled={isDefault} onClick={handleDeletePaymentMethod}>
          Remove
        </MenuItem>
      </Menu>
    </>
  );
};
