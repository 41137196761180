import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  styled,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import HealthNowCard from "../../assets/cards/HealthNowCardFront.png";
import { ReactComponent as Freeze } from '../../assets/freeze.svg';
import { ReactComponent as ReIssue } from '../../assets/reissue.svg';
import {
  CardStatus,
  CardStatusResponse,
  Card as CardType,
  Employee,
} from "../../models";
import { CardDesign, CardDesignTextStyle } from "../../models/cards/cardDesign";
import { fetchData } from "../../utils/fetchData";
import { extraordinaryColors } from "../../utils/theme";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { AllTransactions } from "../modals/AllTransactions";
import { LoadingSpinner } from "./LoadingSpinner";
import { CardReissueReason } from "../../models/cards/cardReissueReason";

type CardProps = {
  cardId?: string;
  employee: Employee;
};

const CardTypeHeader = styled(Typography)({
  fontSize: 20,
  fontWeight: 700,
  marginBottom: 20
})

const CardSuffixHeader = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
  color: "#737373",
  marginLeft: 4
})

const CardDetailLabel = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  color: "#737373"
})

const CardDetailDisplay = styled(Typography)({
  fontSize: 16,
  fontWeight: 600
})

const CardActionHeader = styled(Typography)({
  fontSize: 14,
  fontWeight: 700,
  paddingBottom: 18,
  borderBottom: "2px solid #E4E7EC",
  marginTop: 32,
})

const StartIconContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: `${extraordinaryColors.green.light}26`,
  borderRadius: "50%",
  width: 40,
  height: 40,
})

const CardActionButtonText = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
})

export const Card = ({ cardId, employee }: CardProps) => {
  const { config } = useConfig();
  const { employerId, employees } = useData();
  const { getToken, getBooleanFlag } = useKindeAuth();
  const [error, setError] = useState("");
  const [cardReIssued, setCardReIssued] = useState(false);
  const [isBlockCardModalOpen, setIsBlockCardModalOpen] = useState(false);
  const [isReIssueCardModalOpen, setIsReIssueCardModalOpen] = useState(false);
  const [isAllTransactionsModalOpen, setIsAllTransactionModalOpen] =
    useState(false);
  const [reIssueReason, setReissueReason] = useState<CardReissueReason>(CardReissueReason.LOST);

  const blockCardMutation = useMutation({
    mutationFn: async () => {
      const blockCard = cardStatus.data?.status === CardStatus.Active;

      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}/block`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            blockOption: blockCard ? "BLOCK" : "UNBLOCK"
          })
        }
      );

      if (!response.ok)
        throw new Error(
          `There was a problem ${
            blockCard ? "freezing" : "un-freezing"
          } the card`
        );
    },
    onSuccess: () => {
      toast.success(
        `Card successfully ${
          cardStatus.data?.status === CardStatus.Active
            ? "frozen"
            : "un-frozen"
        }`
      );

      if (cardStatus.data?.status) {
        cardStatus.data.status = CardStatus.Active; // Manually update to the expected state (Avoid need for a loading animation for card)
      }

      cardStatus.refetch();
      cardDetails.refetch();
      employees.refetch();
      setIsBlockCardModalOpen(false);
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const reIssueCardMutation = useMutation({
    mutationFn: async (reissueReason: CardReissueReason) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}/replacement`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            reasonCode: reissueReason
          })
        }
      );
      if (!response.ok)
        throw new Error(`There was a problem re-issuing a card for this user.`);
    },
    onSuccess: () => {
      setCardReIssued(true);
      toast.success("Card successfully re-issued.");
      cardStatus.refetch();
      cardDetails.refetch();
      employees.refetch();
      setIsReIssueCardModalOpen(false);
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const cardDetails = useQuery<CardType>(
    `${config?.API_URL}/employers/${employerId}/cards/${cardId}`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}`,
        getToken
      ),
    {
      enabled: !!cardId
    }
  );

  const cardStatus = useQuery<CardStatusResponse>(
    `${config?.API_URL}/employers/${employerId}/cards/${cardId}/status`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}/status`,
        getToken
      ),
    {
      enabled: !!cardId
    }
  );

  const cardDesign = useQuery<CardDesign>(
    `${config?.API_URL}/employers/${employerId}/cards/${cardId}/design`,
    () =>
      fetchData(
        `${config?.API_URL}/employers/${employerId}/cards/${cardId}/design`,
        getToken
      ),
    {
      enabled: !!cardId
    }
  );

  // Refetch card status / details on employee.cards changing
  useEffect(() => {
    if (!cardId) {
      return;
    }
    cardDetails.refetch();
    cardStatus.refetch();
    cardDesign.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.cards]);

  // Clear error on modal close/open
  useEffect(() => {
    setError("");
  }, [
    isBlockCardModalOpen,
    isReIssueCardModalOpen,
    isAllTransactionsModalOpen
  ]);

  if (
    cardId &&
    (cardDetails.isIdle ||
      cardDetails.isLoading ||
      cardStatus.isIdle ||
      cardStatus.isLoading ||
      cardDesign.isLoading ||
      cardDesign.isIdle)
  ) {
    return (
      <>
        <Grid item xs={12}>
          <LoadingSpinner />
        </Grid>
      </>
    );
  }

  if (cardReIssued) {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h6" mb="16px">
            Card
          </Typography>
          <Typography>Card re-issued</Typography>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Box width={"50%"}>
        <Box display={"flex"} flexDirection={"row"}>
          <CardTypeHeader>
            Card
          </CardTypeHeader>
          <CardSuffixHeader>
            {cardDetails.data?.maskedCardNumber.slice(-4)}
          </CardSuffixHeader>
        </Box>
        
        {cardId && cardStatus.data ? (
          <>
            {cardStatus.data?.status === CardStatus.Removed ? (
              <>
                <Typography>Card is removed</Typography>
                {/*{cardStatus.data?.isReIssuable && (*/}
                {/*  <Button*/}
                {/*    style={{ marginTop: 10 }}*/}
                {/*    variant="outlined"*/}
                {/*    color="error"*/}
                {/*    fullWidth*/}
                {/*    onClick={() => setIsReIssueCardModalOpen(true)}*/}
                {/*  >*/}
                {/*    Re-Issue Card*/}
                {/*  </Button>*/}
                {/*)}*/}
              </>
            ) : (
              <>
                <Box display={"flex"} flexDirection={"row"}>
                  <Box maxWidth={"300px"} mb={1} position="relative">
                    <img
                      src={
                        cardDetails.data?.cardDesignId &&
                        config?.PUBLIC_BUCKET_URL
                          ? `${config.PUBLIC_BUCKET_URL}/card-designs/${cardDetails.data.cardDesignId}/normal.png`
                          : HealthNowCard
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = HealthNowCard;
                      }}
                      alt="HealthNow card"
                      style={{ width: "100%" }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "38px",
                        left: "64px"
                      }}
                    >
                      <Typography sx={{ fontWeight: 500,
                        color:
                          cardDesign.data?.textStyle ===
                          CardDesignTextStyle.BLACK
                            ? "black"
                            : "white",
                        textShadow:
                          cardDesign.data?.textStyle ===
                          CardDesignTextStyle.BLACK
                            ? "1px 1px 2px white"
                            : "1px 1px 2px black"
                      }}>
                        {cardDetails.data?.maskedCardNumber
                          ? cardDetails.data.maskedCardNumber
                              ?.match(/.{1,4}/g)
                              ?.join(" ")
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                  <List sx={{ marginLeft: "auto"}}>
                    <ListItem disablePadding>
                      <Box textAlign={"right"} width={"100%"}>
                        <CardDetailLabel>Status</CardDetailLabel>
                        <CardDetailDisplay>
                          {cardStatus.data.status}
                        </CardDetailDisplay>
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <Box textAlign={"right"} width={"100%"}>
                        <CardDetailLabel>Expires on</CardDetailLabel> 
                        <CardDetailDisplay>
                          {cardDetails.data?.expiry.slice(2, 4)}/
                          {cardDetails.data?.expiry.slice(0, 2)}
                        </CardDetailDisplay>
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <Box textAlign={"right"} width={"100%"}>
                        <CardDetailLabel>Card Type</CardDetailLabel>
                        <CardDetailDisplay>
                          {cardDetails.data?.offering.type}
                        </CardDetailDisplay>
                      </Box>
                    </ListItem>
                  </List>

                </Box>

                <CardActionHeader>Manage Card</CardActionHeader>

                {(cardStatus.data?.status === CardStatus.Inactive ||
                  cardStatus.data?.status === CardStatus.Active) && (
                  <Button
                    variant="text"
                    fullWidth
                    sx={{ paddingY: 4, borderRadius: 0, borderBottom: "1px solid #E4E7EC"}}
                    startIcon={<StartIconContainer><Freeze /></StartIconContainer>}
                    endIcon={<ArrowRight />}
                    onClick={() => setIsBlockCardModalOpen(true)}
                  >
                    <Box mx={"auto"}>
                      <CardActionButtonText>
                        {cardStatus.data?.status === CardStatus.Active
                          ? "Freeze card"
                          : "Un-Freeze Card"}
                      </CardActionButtonText>
                    </Box>
                  </Button>
                )}

                {cardStatus.data?.isReIssuable && (
                  <Button
                    variant="text"
                    sx={{ paddingY: 4, borderRadius: 0, borderBottom: "1px solid #E4E7EC"}}
                    startIcon={<StartIconContainer><ReIssue /></StartIconContainer>}
                    endIcon={<ArrowRight />}
                    fullWidth
                    onClick={() => setIsReIssueCardModalOpen(true)}
                  >
                    <Box mx={"auto"}>
                      <CardActionButtonText>
                        Re-Issue Card
                      </CardActionButtonText>
                    </Box>
                  </Button>
                )}

                <Box display="flex" justifyContent="flex-end">
                  {getBooleanFlag("customer_view_transactions") && (
                    <Button
                      color="secondary"
                      onClick={() => setIsAllTransactionModalOpen(true)}
                    >
                      View transactions
                    </Button>
                  )}
                </Box>
              </>
            )}
          </>
        ) : (
          "User does not have a card"
        )}
      </Box>

      <Dialog
        open={isBlockCardModalOpen}
        onClose={() => setIsBlockCardModalOpen(false)}
      >
        <DialogTitle>
          {cardStatus.data?.status === CardStatus.Active
            ? "Freeze card"
            : "Un-Freeze Card"}
        </DialogTitle>
        <DialogContent>
          {error && <Typography color="error">{error}</Typography>}
          <DialogContentText>
            {cardStatus.data?.status === CardStatus.Active
              ? `Are you sure you want to freeze ${employee.firstName} ${employee.lastName}'s card? They will be unable to use it.`
              : `Are you sure you want to un-freeze ${employee.firstName} ${employee.lastName}'s card?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsBlockCardModalOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={blockCardMutation.isLoading}
            onClick={() => blockCardMutation.mutate()}
            autoFocus
            variant="contained"
          >
          {cardStatus.data?.status === CardStatus.Active
            ? "Freeze"
            : "Un-Freeze"}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {employee.cards.length > 0 && (
        <Dialog
          open={isReIssueCardModalOpen}
          onClose={() => setIsReIssueCardModalOpen(false)}
        >
          <DialogTitle>Re-Issue Card</DialogTitle>
          <DialogContent>
            {error && <Typography color="error">{error}</Typography>}
            <DialogContentText>
              {employee.cards.find((c) => c.id === cardId)?.status ===
              CardStatus.Removed
                ? `Are you sure you want to re-issue a card for ${employee.firstName} ${employee.lastName}?`
                : `Are you sure you want to re-issue ${employee.firstName} ${employee.lastName}'s card? This will cancel their existing card.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <InputLabel>Reason</InputLabel>
            <Select
              value={reIssueReason}
              onChange={(e) => setReissueReason(e.target.value as CardReissueReason)}
              sx={{ marginRight: "auto"}}
            >
              {
                Object.keys(CardReissueReason).map((reason) => 
                  <MenuItem value={CardReissueReason[reason as keyof typeof CardReissueReason]}>
                    {reason}
                  </MenuItem>
                )
              }
            </Select>

            <Button
              onClick={() => setIsReIssueCardModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={reIssueCardMutation.isLoading}
              onClick={() => reIssueCardMutation.mutate(reIssueReason)}
              autoFocus
              variant="contained"
            >
              Re-Issue Card
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {isAllTransactionsModalOpen && (
        <AllTransactions
          open={true}
          handleClose={() => setIsAllTransactionModalOpen(false)}
          employeeId={employee.id}
        />
      )}
    </>
  );
};
