export interface EmployerPaymentMethod {
  id: string;
  employerId: string;
  type: EmployerPaymentMethodType;
  directDebitMandateId?: string; // Used for DIRECT_DEBIT (GoCardless)
  directDebitDetails?: EmployerPaymentMethodDirectDebitDetails;
  cardDetails?: EmployerPaymentMethodCardDetails;
  cardPaymentMethodId?: string; // Used for CARD (Stripe)
  isDefault: boolean;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface EmployerPaymentMethodDirectDebitDetails {
  id: string;
  accountNumberEnding: string;
  accountHolderName: string;
  bankName: string;
  currency: string;
  countryCode: string;
}

export interface EmployerPaymentMethodCardDetails {
  last4: string;
  expMonth: number;
  expYear: number;
  brand: string;
}

export enum EmployerPaymentMethodType {
  DIRECT_DEBIT = "DIRECT_DEBIT",
  CARD = "CARD"
}
