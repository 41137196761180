import { Stack, TextField } from "@mui/material";
import React from "react";

type PaginatedDateRangeFilterProps = {
  startDate?: Date;
  endDate?: Date;
  updateStartDate: (value: Date) => void;
  updateEndDate: (value: Date) => void;
};

export const PaginatedDateRangeFilter: React.FC<
  PaginatedDateRangeFilterProps
> = ({ startDate, endDate, updateStartDate, updateEndDate }) => {
  const formatDate = (date: Date) => date.toISOString().split("T")[0];

  const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    updateStartDate(new Date(value));
  };

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    updateEndDate(new Date(value));
  };

  return (
    <Stack direction="row" spacing={2} my={2}>
      <TextField
        type="date"
        label="Start Date"
        value={startDate ? formatDate(startDate) : ""}
        onChange={handleStartChange}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        type="date"
        label="End Date"
        value={endDate ? formatDate(endDate) : ""}
        onChange={handleEndChange}
        InputLabelProps={{ shrink: true }}
      />
    </Stack>
  );
};
